import TranslatedEnum from './TranslatedEnum.js'

export default class TransportModusEnum extends TranslatedEnum {
  static get KEYS() {
    return {
      RAIL_TRANSPORT: 'RAIL_TRANSPORT',
      ROAD_TRANSPORT: 'ROAD_TRANSPORT',
      AIR_TRANSPORT: 'AIR_TRANSPORT',
      FIXED_TRANSPORT_INSTALLATIONS: 'FIXED_TRANSPORT_INSTALLATIONS',
      INLAND_WATERWAY_TRANSPORT: 'INLAND_WATERWAY_TRANSPORT',
      MODE_UNKNOWN: 'MODE_UNKNOWN'
    }
  }

  static get I18N() {
    return {
      de: this.KEYS,
      fr: this.KEYS,
      it: this.KEYS
    }
  }
}
