import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import DateTimeCell from '@isceco/widget-library2/basic-elements/List/cell/DateTimeCell.js'
import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import DropdownMultiselectColumn from '@isceco/widget-library2/basic-elements/List/column/DropdownMultiselectColumn.js'
import TypeEnum from '../../enum/TypeEnum.js'
import AnfrageStatusEnum from '../../enum/AnfrageStatusEnum.js'
import VerarbeitungStatusEnum from '../../enum/VerarbeitungStatusEnum.js'
import CheckboxColumn from '@isceco/widget-library2/basic-elements/List/column/CheckboxColumn.js'
import QueriesService from '../../services/QueriesService.js'
import CommentCell from './CommentCell.js'

export default class QueriesColumns {
  static get UPDATE_TABLE() {
    return 'update-queries-table'
  }

  static getCITESExportColumns() {
    return [
      this.TIME(),
      this.PERMIT_ID(),
      this.DESTINATION(),
      this.POSITION_NUMBER(),
      this.DEPRECIATION_AMOUNT(),
      this.DEPRECIATION_AMOUNT_UNIT(),
      this.LATIN_NAME(),
      this.TYPE(),
      this.ANFRAGE_STATUS(),
      this.VERARBEITUNG_STATUS(),
      this.PROCESSED(),
      this.COMMENT()
    ]
  }

  static getEDAVColumns() {
    return [
      this.TIME(),
      this.PERMIT_ID(),
      this.DISPATCH_COUNTRY(),
      this.NCT_NUMBER(),
      this.CONTROL_CODE(),
      this.NET_MASS(),
      this.ADDITIONAL_MASS(),
      this.MODE_OF_TRANSPORT(),
      this.TYPE(),
      this.ANFRAGE_STATUS(),
      this.VERARBEITUNG_STATUS(),
      this.PROCESSED(),
      this.COMMENT()
    ]
  }

  static getNTLMColumns() {
    return this.getEDAVColumns()
  }

  static getIUUColumns() {
    return [
      this.TIME(),
      this.DISPATCH_COUNTRY(),
      this.ORIGIN_COUNTRY(),
      this.REFERNECE_NUMBER(),
      this.PERMIT_ID(),
      this.NCT_NUMBER(),
      this.NET_MASS(),
      this.TYPE(),
      this.ANFRAGE_STATUS(),
      this.VERARBEITUNG_STATUS(),
      this.IMPORTER(),
      this.REPRESENTATIVE(),
      this.PROCESSED(),
      this.COMMENT()
    ]
  }

  static getPhytoColumns() { // NOSONAR identical to EDAV
    return [
      this.TIME(),
      this.PERMIT_ID(),
      this.DISPATCH_COUNTRY(),
      this.NCT_NUMBER(),
      this.CONTROL_CODE(),
      this.NET_MASS(),
      this.ADDITIONAL_MASS(),
      this.MODE_OF_TRANSPORT(),
      this.TYPE(),
      this.ANFRAGE_STATUS(),
      this.VERARBEITUNG_STATUS(),
      this.PROCESSED(),
      this.COMMENT()
    ]
  }

  //   **************** GENERAL *****************

  static PERMIT_ID() {
    return new StringColumn({
      key: 'permitId',
      text: 'table.header.permitId',
      sortable: true
    })
  }

  static TYPE() {
    return new DropdownMultiselectColumn({
      items: TypeEnum.ITEMS(),
      key: 'type',
      text: 'table.header.type',
      sortable: true
    })
  }

  static ANFRAGE_STATUS() {
    return new DropdownMultiselectColumn({
      items: AnfrageStatusEnum.ITEMS(),
      key: 'ok',
      text: 'table.header.anfrageStatus',
      sortable: true
    })
  }

  static VERARBEITUNG_STATUS() {
    return new DropdownMultiselectColumn({
      items: VerarbeitungStatusEnum.ITEMS(),
      key: 'status',
      text: 'table.header.status',
      sortable: true
    })
  }

  static TIME() {
    return new GenericColumn({
      key: 'time',
      text: 'table.header.time',
      cell: new DateTimeCell(),
      sortable: true
    })
  }

  static MODE_OF_TRANSPORT() {
    return new StringColumn({
      key: 'modeOfTransport',
      text: 'table.header.modeOfTransport',
      sortable: false
    })
  }

  static PROCESSED() {
    return new CheckboxColumn({
      key: 'processed',
      text: 'table.header.processed',
      sortable: true,
      align: 'center',
      callback: (value, row) => {
        new QueriesService().update({id: row.id, processed: value})
          .then(_ => send(QueriesColumns.UPDATE_TABLE))
      }
    })
  }

  static COMMENT() {
    return new GenericColumn({
      key: 'comment',
      text: 'table.header.comment',
      align: 'right',
      cell: new CommentCell()
    })
  }

  //   **************** CITES_EXPORT *****************

  static DESTINATION() {
    return new StringColumn({
      key: 'destination',
      text: 'table.header.destination',
      sortable: true
    })
  }

  static DEPRECIATION_AMOUNT() {
    return new StringColumn({
      key: 'depreciationAmount',
      text: 'table.header.depreciationAmount',
      sortable: true
    })
  }

  static DEPRECIATION_AMOUNT_UNIT() {
    return new StringColumn({
      key: 'depreciationAmountUnit',
      text: 'table.header.depreciationAmountUnit',
      sortable: true
    })
  }

  static LATIN_NAME() {
    return new StringColumn({
      key: 'latinNameStr',
      text: 'table.header.latinName',
      sortable: true
    })
  }

  static POSITION_NUMBER() {
    return new StringColumn({
      key: 'permitPositionNumber',
      text: 'table.header.permitPositionNumber',
      sortable: true
    })
  }

  //   **************** EDAV *****************

  static DISPATCH_COUNTRY() {
    return new StringColumn({
      key: 'dispatch',
      text: 'table.header.dispatch',
      sortable: true
    })
  }

  static NCT_NUMBER() {
    return new StringColumn({
      key: 'nctNumber',
      text: 'table.header.nctNumber',
      sortable: true
    })
  }

  static NET_MASS() {
    return new StringColumn({
      key: 'netMass',
      text: 'table.header.netMass',
      sortable: true
    })
  }

  static ADDITIONAL_MASS() {
    return new StringColumn({
      key: 'additionalMass',
      text: 'table.header.additionalMass',
      sortable: true
    })
  }

  static CONTROL_CODE() {
    return new StringColumn({
      key: 'controlCode',
      text: 'table.header.controlCode',
      sortable: true
    })
  }

  //   **************** Phyto *****************

  static ORIGIN_COUNTRY() {
    return new StringColumn({
      key: 'origin',
      text: 'table.header.origin',
      sortable: true
    })
  }

  static REFERNECE_NUMBER() {
    return new StringColumn({
      key: 'referenceNumber',
      text: 'table.header.referenceNumber',
      sortable: true
    })
  }

  static IMPORTER() {
    return new StringColumn({
      key: 'importerName',
      text: 'table.header.importer',
      sortable: true
    })
  }

  static REPRESENTATIVE() {
    return new StringColumn({
      key: 'representativeName',
      text: 'table.header.representative',
      sortable: true
    })
  }
}
